import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import Tooltip from '@mui/material/Tooltip';

const NavigationMenu = ({ navTransition }) => {

    const [navChoice, setNavChoice] = useState("home");

    return (
        <motion.ul
            variants={navTransition}
            initial="hidden"
            animate="show"
            className="nav-container">
            <Tooltip title="Home" placement="top-start">
                <Link to="/" className={navChoice === "home" ? "scaled" : ""} onClick={() => { setNavChoice("home") }}>
                    <li className="nav-options">
                        <i class="fa-solid fa-house"></i>
                    </li>
                </Link>
            </Tooltip>
            <Tooltip title="Projects" placement="top-start">
                <Link to="/projects" className={navChoice === "project" ? "scaled" : ""} onClick={() => { setNavChoice("project") }}>
                    <li className="nav-options">
                        <i class="fa-solid fa-laptop-code"></i>
                    </li>
                </Link>
            </Tooltip>
            <Tooltip title="Experience" placement="top-start">
                <Link to="/experience" className={navChoice === "experience" ? "scaled" : ""} onClick={() => { setNavChoice("experience") }}>
                    <li className="nav-options">
                        <i class="fa-solid fa-suitcase"></i>
                    </li>
                </Link>
            </Tooltip>
            {/* <Tooltip title="Feedback" placement="top-start">
                <Link to="/feedback" className={navChoice === "feedback" ? "scaled" : ""} onClick={() => { setNavChoice("feedback") }}>
                    <li className="nav-options">
                        <i class="fa-solid fa-comment"></i>
                    </li>
                </Link>
            </Tooltip>
            <Tooltip title="Learn" placement="top-start">
                <Link to="/learn" className={navChoice === "learn" ? "scaled" : ""} onClick={() => { setNavChoice("learn") }}>
                    <li className="nav-options">
                        <i class="fa-solid fa-chalkboard-user"></i>
                    </li>
                </Link>
            </Tooltip> */}
        </motion.ul>
    )
}

export default NavigationMenu;