import { motion } from "framer-motion";

const Feedback = ({ flipTransition }) => {
    return(
        <motion.div 
        variants={flipTransition}
        initial="hidden"
        animate="show"
        >
        Feedback
        </motion.div>
    )
}

export default Feedback;