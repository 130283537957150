import { motion } from "framer-motion";

const Experience = ({ flipTransition }) => {
    return (
        <motion.div
            variants={flipTransition}
            initial="hidden"
            animate="show"
        >
            {/* Experience */}
            <h2 className="heading">Experience</h2>
            <div className="experiences">
                <div className="experience-con">
                    <div className="exp-name-year">
                        <h3 className="exp-name">Software Developer Intern</h3>
                        <h5 className="exp-year">2023</h5>
                    </div>

                    <h4 className="exp-company">BrainyBeam Technologies</h4>
                    <ul className="exp-desc">
                        <li>
                            Optimized application programming interfaces (APIs) to reduce loading time by 2 seconds,
                            enhancing overall system performance and user satisfaction.
                        </li>
                        <li>
                            Collaborated closely with team members in the development of front-end components using
                            ReactJS, fostering efficient teamwork and delivering high-quality user interfaces.
                        </li>
                    </ul>
                </div>
                <div className="experience-con">
                    <div className="exp-name-year">
                        <h3 className="exp-name">NodeJS Developer Intern</h3>
                        <h5 className="exp-year">2022</h5>
                    </div>

                    <h4 className="exp-company">FRSHR Technologies</h4>
                    <ul className="exp-desc">
                        <li>
                            Accomplished the development of a karaoke website for Radio Mirchi India, facilitating
                            successful deployment, by employing a combination of frontend and backend technologies.
                        </li>
                        <li>
                            Maintained code quality and collaboration standards by actively monitoring GitHub branches
                            and pull requests, resulting in reduced bugs, and facilitating smooth project progression.
                        </li>
                    </ul>
                </div>


                {/* Education */}
                <h2 className="heading">Education</h2>
                <div className="universities">
                    <div className="university">
                        <div className="exp-name-year">
                            <h3 className="exp-name">Master of Professional Studies in Informatics</h3>
                            <h5 className="exp-year">2023-Present</h5>
                        </div>
                        <h4 className="exp-company">Northeastern University, Toronto</h4>
                    </div>
                    <div className="university">
                        <div className="exp-name-year">
                            <h3 className="exp-name">Bachelor of Engineering in Information Technology</h3>
                            <h5 className="exp-year">2019-2023</h5>
                        </div>
                        <h4 className="exp-company">G H Patel College of Engineering & Technology, Vidyanagar</h4>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Experience;